import React, { useState, useMemo, useEffect } from "react";
import { 
    Modal, 
    Typography, 
    Select, 
    Input, 
    Row, 
    Col, 
    List, 
    Card, 
    Button,
    Checkbox
} from 'antd';
import {
    LoadingOutlined, 
    MergeOutlined, 
    ProfileOutlined,
    PlusOutlined,
    DeleteOutlined,
    FontSizeOutlined,
    FieldBinaryOutlined,
    FunctionOutlined,
    PlayCircleFilled,
    PlayCircleOutlined,
    HomeOutlined
} from '@ant-design/icons';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextArea from "antd/es/input/TextArea";
import { Collapse } from 'antd';
import { fetchWorksheetData } from "../../endpoints/fetchWorksheetData";
import { formatTimeAgo } from "../utils";
import dayjs from "dayjs";

// Helper to get icon for a given column type
const getColumnTypeIcon = (colType) => {
    if (colType === 'text') {
        return <FontSizeOutlined style={{ color: '#918d8d' }} />;
    } else if (colType === 'number') {
        return <FieldBinaryOutlined style={{ color: '#918d8d' }} />;
    } else if (colType === 'enrichment') {
        return <PlayCircleOutlined style={{ color: '#918d8d' }} />;
    } else {
        return null;
    }
};

// Define config for each enrichment type
const enrichmentConfigs = {
    quantity_sold: {
        name: 'Quantity Sold',
        description: 'Lookup the quantity sold of product for imported locations',
        column_name: 'Qty Sold',
        column_type: 'enrichment',
        category: 'Locations'
    },
    aggregate: {
        name: 'Cross Reference Lookup',
        description: 'Add a column with aggregated data from a worsheet by value exact match',
        column_name: 'Aggregated Values',
        category: 'Aggregate',
        column_type: 'enrichment',
    },
    transform: {
        name: 'Transform',
        description: 'Transform data using AI',
        category: 'Transform',
        column_name: 'Transformed Values',
        column_type: 'enrichment',
    },
    associate: {
        name: 'AI Match',
        description: 'Match rows to rows in another worksheet using AI',
        category: 'Match & Normalize',
        column_name: 'Matched Values',
        column_type: 'enrichment',
    },
    product_id: {
        name: 'Match Product',
        description: 'Match a description or product code column against your product master list',
        column_name: 'Product',
        column_type: 'enrichment',
        category: 'Match & Normalize'
    },
};

// Icons for each category
const categoryIcons = {
    'Locations': <HomeOutlined />,
    'Aggregate': <ProfileOutlined />,
    'Transform': <FontAwesomeIcon icon={faWandMagicSparkles} />,
    'Match & Normalize': <MergeOutlined />,
};

const EnrichmentFlow = ({
    userData,
    open,
    products,
    editingEnrichmentConfigBody,
    onClose,
    onOk,
    columns,
    currentWorksheetId,
    rows
}) => {

    // If editingEnrichmentConfigBody is non-null, we are "editing" an existing enrichment
    const isEditing = !!editingEnrichmentConfigBody;

    const [selectedCategory, setSelectedCategory] = useState('Locations');
    const [selectedType, setSelectedType] = useState(null);

    // Single "input" column for cross-reference, transform, or multiple for AI match
    const [selectedColumns, setSelectedColumns] = useState([]);

    // For naming the new (destination) column
    const [newColumnName, setNewColumnName] = useState('');

    // For AI custom prompt
    const [newPrompt, setNewPrompt] = useState('');

    // For referencing other worksheets
    const [selectedWorksheet, setSelectedWorksheet] = useState(null);
    const [worksheetOptions, setWorksheetOptions] = useState([]);
    const [filteredWorksheetOptions, setFilteredWorksheetOptions] = useState([]);
    
    // We'll store `null` while fetching columns; store an array once fetched
    const [worksheetColumnOptions, setWorksheetColumnOptions] = useState(null);
    const [selectedWorksheetColumn, setSelectedWorksheetColumn] = useState(null);

    // For AI associate option
    const [leaveBlank, setLeaveBlank] = useState(true);

    // For cross-reference aggregator column
    const [crossRefAggColumn, setCrossRefAggColumn] = useState(null);
    const [crossRefAggMethod, setCrossRefAggMethod] = useState(null);
    const [selectedProductsLoc, setSelectedProductsLoc] = useState(['*']);
    const [dateRangesLoc, setDateRangesLoc] = useState([]);
    const [selectedDateRangesLoc, setSelectedDateRangesLoc] = useState([]);
    const [loadingDateRangesLoc, setLoadingDateRangesLoc] = useState(false);

    // 1) Group the config items by category
    const categories = useMemo(() => {
        const temp = {};
        Object.entries(enrichmentConfigs).forEach(([key, val]) => {
            if (!temp[val.category]) temp[val.category] = [];
            temp[val.category].push({ type: key, ...val });
        });
        return Object.entries(temp).map(([catName, items]) => ({
            catName,
            items
        }));
    }, []);

    // Helper to reset all fields (if needed)
    const resetFields = (keepCategory = false) => {
        setSelectedType(null);

        if (!keepCategory)
            setSelectedCategory('Locations');

        setNewColumnName('');
        setSelectedColumns([]);
        setNewPrompt('');
        setLeaveBlank(true);
        setSelectedWorksheet(null);
        setSelectedWorksheetColumn(null);
        setFilteredWorksheetOptions(worksheetOptions);
        setCrossRefAggColumn(null);
        setCrossRefAggMethod(null);

        // Reset "Locations" fields
        setSelectedProductsLoc(['*']);
        setDateRangesLoc([]);
        setSelectedDateRangesLoc([]);
        setLoadingDateRangesLoc(false);
    };

    useEffect(() => {
        // set name from relevant enrichmentConfigs item
        setNewColumnName(
            editingEnrichmentConfigBody?.column_name || enrichmentConfigs[selectedType]?.column_name 
        );
    }, [selectedType]);

    // 2) If editingEnrichmentConfigBody is present, initialize states from it
    useEffect(() => {
        console.log('editingEnrichmentConfigBody', editingEnrichmentConfigBody);
        if (!editingEnrichmentConfigBody) {
            resetFields();
            return;
        }

        const theType = editingEnrichmentConfigBody.type;
        setSelectedType(theType);

        // Get the category from our enrichmentConfigs
        const config = enrichmentConfigs[theType] || {};
        const cat = config.category || 'Aggregate';
        setSelectedCategory(cat);

        // Column name
        setNewColumnName(editingEnrichmentConfigBody.column_name || '');

        // Local columns chosen
        setSelectedColumns(editingEnrichmentConfigBody.inputCols || []);

        // AI prompt + blank check
        setNewPrompt(editingEnrichmentConfigBody.newPrompt || '');
        setLeaveBlank(
            editingEnrichmentConfigBody.leaveBlank === false
                ? false
                : true
        );

        // Worksheet ID from S3 key
        const s3Key = editingEnrichmentConfigBody.selectedWorksheetS3Key;
        if (s3Key) {
            const parts = s3Key.split('/');
            const lastPart = parts[parts.length - 1];
            const sheetId = lastPart.replace('.json', '');
            setSelectedWorksheet(sheetId);
        }

        // Worksheet column
        setSelectedWorksheetColumn(editingEnrichmentConfigBody.selectedWorksheetColumn || null);
        
        // aggregator
        if (theType === 'aggregate') {
            const aggCols = editingEnrichmentConfigBody.crossRefAggColumns || [];
            setCrossRefAggColumn(aggCols.length ? aggCols[0] : null);
            setCrossRefAggMethod(editingEnrichmentConfigBody.crossRefAggMethod || null);
        } else if (theType === 'quantity_sold') {
            setSelectedDateRangesLoc(editingEnrichmentConfigBody.selectedDateRanges.map((d) => {
                // Convert to iso format with time (no milliseconds)
                const parts = d.split(' - ');
                const newRange = `${dayjs(parts[0]).format('YYYY-MM-DDTHH:mm:ss')} - ${dayjs(parts[1]).format('YYYY-MM-DDTHH:mm:ss')}`;
                return newRange;
            }));
            setSelectedProductsLoc(editingEnrichmentConfigBody.selectedProducts);
        }

        // If we ever decide to handle pre-populating "Locations" advanced fields from existing data, do it here
    }, [editingEnrichmentConfigBody]);

    // 3) Handle user clicking OK => build final object
    const handleOk = () => {
        let selectedFolder = '';
        if (selectedWorksheet) {
            const selectedSheetData = worksheetOptions.find((o) => o.value === selectedWorksheet);
            selectedFolder = selectedSheetData?.folder || '';
        }

        // For aggregator, store a single column in an array for consistency
        const crossRefAggColumns = crossRefAggColumn ? [crossRefAggColumn] : [];
        
        // If user chooses "Locations" category, gather product/dateRange states
        const locSpecificConfig = (enrichmentConfigs[selectedType]?.category === 'Locations')
            ? {
                selectedProducts: selectedProductsLoc,
                selectedDateRanges: selectedDateRangesLoc
            }
            : {};

        const enrichmentConfig = {
            ...enrichmentConfigs[selectedType],
            column_name: newColumnName,
            inputCols: selectedColumns,
            selectedWorksheetS3Key: (
                selectedFolder
                    ? `${selectedFolder}/${selectedWorksheet}.json`
                    : `${selectedWorksheet}.json`
            ),
            selectedWorksheetColumn,
            newPrompt,
            leaveBlank,
            crossRefAggColumns,
            crossRefAggMethod,
            ...locSpecificConfig
        };

        resetFields();

        onOk(
            selectedType,
            selectedColumns,
            enrichmentConfig
        );
    };

    // 4) Compute if the "Ok" button should be disabled
    //    Also disable for Locations if not all rows have `location_id`.
    const isOkDisabled = useMemo(() => {
        if (!selectedType) return true;
        const category = enrichmentConfigs[selectedType].category;

        // Must have at least one selected input column
        if ((!selectedColumns.length || !selectedColumns[0]) && (category !== 'Locations')) return true;
        
        if (category === 'Match & Normalize') {
            // require target worksheet + column
            if (!selectedWorksheet || !selectedWorksheetColumn) return true;
        }

        if (category === 'Aggregate') {
            // require a target worksheet, a ref column, aggregator column, aggregator method
            if (!selectedWorksheet || !selectedWorksheetColumn) return true;
            if (!crossRefAggColumn) return true;
            if (!crossRefAggMethod) return true;
        }

        if (category === 'Transform') {
            // no strict requirement here, optional prompt
        }

        if (category === 'Locations') {
            if (!rows.length || !rows.every((r) => r.location_id)) {
                return true;
            }
            // date select must have at least one entry
            if (!selectedDateRangesLoc.length) return true;
        }

        return false;
    }, [
        selectedType,
        selectedColumns,
        selectedWorksheet,
        selectedWorksheetColumn,
        crossRefAggColumn,
        crossRefAggMethod,
        newPrompt,
        newColumnName,
        rows,
        selectedProductsLoc,
        selectedDateRangesLoc
    ]);

    // We'll show this if disabled because the sheet lacks location_id
    const locationRowsDisabledMsg = useMemo(() => {
        if (
            selectedType &&
            enrichmentConfigs[selectedType].category === 'Locations' &&
            (!rows.length || !rows.every((r) => r.location_id))
        ) {
            return "Location enrichments only available on imported Location rows";
        }
        return "";
    }, [selectedType, rows]);

    // 5) Load list of available worksheets once
    const getWorksheets = async () => {
        const body = { action: 'list_worksheets' };
        const data = await fetchWorksheetData(userData.accessToken, body);
        if (data.error) {
            console.error('Error fetching worksheets', data.error);
        } else {
            const options = (data.metadata || [])
                .filter((o) => !o.isFolder && o.id !== currentWorksheetId)
                .map((o) => ({
                    label: ((o.folder ? (o.folder + ' / ') : '') + o.name),
                    value: o.id,
                    folder: o.folder,
                    last_indexed_at: o.last_indexed_at
                }));
            setWorksheetOptions(options);
            setFilteredWorksheetOptions(options);
        }
    };

    // 6) Load columns from selected worksheet
    const getWorksheetColumns = async (worksheetId, folder) => {
        // Indicate we are loading
        setWorksheetColumnOptions(null);

        const body = { 
            action: 'list_worksheet_columns',
            worksheet_id: worksheetId,
            folder: folder
        };
        const data = await fetchWorksheetData(userData.accessToken, body);
        if (data.error) {
            console.error('Error fetching worksheet columns', data.error);
            // you may want to handle error gracefully
            setWorksheetColumnOptions([]);
        } else {
            const options = (data.cols || []).map((o) => {
                const icon = getColumnTypeIcon(o.column_type || 'text');
                return {
                    label: (
                        <span>
                            {icon} {o.name}
                        </span>
                    ),
                    value: o.key,
                    dataType: o.column_type || 'text'
                };
            });
            setWorksheetColumnOptions(options);
        }
    };

    const selectWorksheet = (value) => {
        setSelectedWorksheet(value);
        // reset columns aggregator state
        setSelectedWorksheetColumn(null);
        setCrossRefAggColumn(null);
        setCrossRefAggMethod(null);
    };

    // 7) Aggregator method options depend on aggregator column type
    const aggregatorOptions = useMemo(() => {
        if (!crossRefAggColumn || !worksheetColumnOptions) return [];
        const colObj = worksheetColumnOptions.find(o => o.value === crossRefAggColumn);
        const dataType = colObj?.dataType || 'text';

        if (dataType === 'number') {
            return [
                { label: 'Count', value: 'count' },
                { label: 'Sum', value: 'sum' },
                { label: 'Average', value: 'average' },
            ];
        } else {
            return [
                { label: 'Select first', value: 'first' },
                { label: 'Count', value: 'count' },
                { label: 'Join with commas', value: 'join' },
            ];
        }
    }, [crossRefAggColumn, worksheetColumnOptions]);

    // 8) When user loads or changes userData, fetch worksheets
    useEffect(() => {
        if (userData?.accessToken) {
            getWorksheets();
        }
    }, [userData]);

    // 9) If user picks a new worksheet, load its columns
    useEffect(() => {
        if (!selectedWorksheet) {
            setWorksheetColumnOptions(null);
            return;
        }
        const folderOption = worksheetOptions.find((o) => o.value === selectedWorksheet);
        const folder = folderOption?.folder || '';
        getWorksheetColumns(selectedWorksheet, folder);
    }, [selectedWorksheet, worksheetOptions]);

    // 10) Build local column options with icons
    const localColumnOptions = useMemo(() => {
        return columns
            .filter((c) => c.key !== 'rowIndex' && c.key !== 'addColumn')
            .map((c) => {
                const icon = getColumnTypeIcon(c.column_type || 'text');
                return {
                    label: (
                        <span>
                            {icon} {c.name}
                        </span>
                    ),
                    value: c.key
                };
            });
    }, [columns]);

    /**
     * Helper to ensure that if "selectedWorksheetColumn" is not yet in the loaded options,
     * we temporarily show undefined. This prevents the raw "value" from showing.
     */
    const safeWorksheetColumnValue = useMemo(() => {
        if (!worksheetColumnOptions) return undefined; // still loading
        if (!selectedWorksheetColumn) return undefined;
        // If the user had chosen a column that is in the list, show it
        const found = worksheetColumnOptions.find(opt => opt.value === selectedWorksheetColumn);
        return found ? found.value : undefined;
    }, [selectedWorksheetColumn, worksheetColumnOptions]);

    /**
     * Similarly for aggregator column
     */
    const safeAggColumnValue = useMemo(() => {
        if (!worksheetColumnOptions) return undefined; // still loading
        if (!crossRefAggColumn) return undefined;
        const found = worksheetColumnOptions.find(opt => opt.value === crossRefAggColumn);
        return found ? found.value : undefined;
    }, [crossRefAggColumn, worksheetColumnOptions]);


    // Whenever products change, simulate fetching date coverage
    useEffect(() => {
        if (enrichmentConfigs[selectedType]?.category === 'Locations') {
            setLoadingDateRangesLoc(true);

            fetchWorksheetData(userData.accessToken, {
              action: 'fetch_location_coverage',
              productIds: selectedProductsLoc.includes('All') ? [] : selectedProductsLoc
            }).then((data) => {
                const coverage = data?.covered_intervals || [];
                coverage.sort((a,b) => new Date(b.StartTime) - new Date(a.StartTime));
                console.log(coverage.map(c => `${c.StartTime} - ${c.EndTime}`))
                setDateRangesLoc(coverage.map(c => `${c.StartTime} - ${c.EndTime}`));
                setLoadingDateRangesLoc(false);
            }, 700);
        }
    }, [selectedProductsLoc, selectedType]);

    return (
        <Modal
            title={isEditing ? "Edit Enrichment" : "Add enriched column"}
            open={open}
            onOk={handleOk}
            onCancel={onClose}
            width={800}
            okText={<>
                <PlayCircleOutlined style={{marginRight: 5}}/>
                {isEditing ? "Run" : "Add & Run"}
            </>}
            okButtonProps={{ disabled: isOkDisabled }}
        >
            {/* If location-based is disabled, show reason */}
            {locationRowsDisabledMsg && (
                <Typography.Text type="danger" style={{ marginBottom: 10, display: 'block' }}>
                    {locationRowsDisabledMsg}
                </Typography.Text>
            )}

            <Row gutter={15}>
                {/* Hide the side bar if we are editing an existing enrichment */}
                {!isEditing && (
                    <Col span={8}>
                        <List
                            header={<Typography.Title level={5}>Tools</Typography.Title>}
                            bordered
                            style={{height: '100%'}}
                            dataSource={categories}
                            renderItem={(cat) => (
                                <List.Item
                                    style={{
                                        cursor: 'pointer',
                                        backgroundColor: 
                                            cat.catName === selectedCategory 
                                            ? '#e6f7ff' 
                                            : 'inherit'
                                    }}
                                    onClick={() => {
                                        setSelectedCategory(cat.catName);
                                        resetFields(true);                                        
                                    }}
                                >
                                    <List.Item.Meta
                                        avatar={categoryIcons[cat.catName] || null}
                                        title={cat.catName}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                )}

                {/* Right side: Enrichment options + input setup */}
                <Col span={isEditing ? 24 : 16}>
                    {/* If not editing AND no selectedType, show the card grid for picking an enrichment */}
                    {(!selectedType && !isEditing) && (
                        <Row gutter={[16, 16]}>
                            {categories
                                .find((c) => c.catName === selectedCategory)
                                ?.items.map((item) => (
                                    <Col span={12} key={item.type}>
                                        <Card 
                                            hoverable 
                                            title={item.name}
                                            onClick={() => setSelectedType(item.type)}
                                        >
                                            <p>{item.description}</p>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                    )}

                    {/* ============= AGGREGATE ============= */}
                    {selectedType && enrichmentConfigs[selectedType].category === 'Aggregate' && (
                        <>
                            <Typography.Text>New column name</Typography.Text>
                            <br />
                            <Input
                                style={{ width: '100%', marginTop: 8 }}
                                value={newColumnName}
                                onChange={(e) => setNewColumnName(e.target.value)}
                            />
                            <br />
                            <br />

                            <Typography.Title level={5} style={{ marginTop: 16 }}>
                                Setup Inputs
                            </Typography.Title>

                            {/* Single input column from current sheet */}
                            <Typography.Text>Choose column from this worksheet to match row values</Typography.Text>
                            <br />
                            <Select
                                placeholder="Select a column"
                                style={{ width: '100%', marginTop: 8 }}
                                options={localColumnOptions}
                                onChange={(val) => setSelectedColumns([val])}
                                value={selectedColumns[0] || undefined}
                            />

                            <br />
                            <br />
                            <Typography.Text style={{ marginTop: 15 }}>
                                Select worksheet to cross reference against
                            </Typography.Text>
                            <br/>
                            <Select 
                                style={{ width: '100%', marginTop: 8 }}
                                options={worksheetOptions}
                                onChange={selectWorksheet}
                                value={selectedWorksheet || undefined}
                                placeholder="Select worksheet"
                            />

                            <br />
                            <br />

                            {/* Show spinner if columns are not yet loaded */}
                            {selectedWorksheet && !worksheetColumnOptions && <LoadingOutlined />}

                            {selectedWorksheet && worksheetColumnOptions && (
                                <>
                                    <Typography.Text style={{ marginTop: 15 }}>
                                        Select column from "{
                                            worksheetOptions.find((o) => o.value === selectedWorksheet)?.label
                                        }" to match values against column "{columns.find((c) => c.key === selectedColumns[0])?.name}"
                                    </Typography.Text>
                                    <br/>
                                    {/* Single column in the other sheet to match against */}
                                    <Select 
                                        style={{ width: '100%', marginTop: 8 }}
                                        options={worksheetColumnOptions}
                                        loading={!worksheetColumnOptions}
                                        onChange={setSelectedWorksheetColumn}
                                        value={safeWorksheetColumnValue}
                                        placeholder="Select column"
                                    />

                                    {/* Single aggregator column */}
                                    <br />
                                    <br />
                                    <Typography.Text style={{ marginTop: 15 }}>
                                        Select column to aggregate values from
                                    </Typography.Text>
                                    <br />
                                    <Select
                                        placeholder="Select a column"
                                        style={{ width: '100%', marginTop: 8 }}
                                        options={worksheetColumnOptions}
                                        loading={!worksheetColumnOptions}
                                        onChange={setCrossRefAggColumn}
                                        value={safeAggColumnValue}
                                    />

                                    <br />
                                    <br />
                                    <Typography.Text style={{ marginTop: 15 }}>
                                        Select aggregation method
                                    </Typography.Text>
                                    <br/>
                                    <Select
                                        style={{ width: '100%', marginTop: 8 }}
                                        options={aggregatorOptions}
                                        onChange={(val) => setCrossRefAggMethod(val)}
                                        value={crossRefAggMethod || undefined}
                                        placeholder="Select aggregation method"
                                    />
                                </>
                            )}
                        </>
                    )}

                    {/* ============= MATCH & NORMALIZE ============= */}
                    {selectedType && enrichmentConfigs[selectedType].category === 'Match & Normalize' && (
                        <>
                            <Typography.Text>New column name</Typography.Text>
                            <br />
                            <Input
                                style={{ width: '100%', marginTop: 8 }}
                                value={newColumnName}
                                onChange={(e) => setNewColumnName(e.target.value)}
                            />
                            <br />
                            <br />
                            <Typography.Title level={5} style={{ marginTop: 16 }}>
                                Setup Inputs
                            </Typography.Title>

                            <Typography.Text>Choose the input column(s)</Typography.Text>
                            <br />
                            {selectedColumns.map((col, idx) => (
                                <div 
                                    key={idx} 
                                    style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}
                                >
                                    <Select
                                        placeholder="Select a column"
                                        style={{ width: '100%' }}
                                        options={localColumnOptions}
                                        onChange={(val) => {
                                            const newCols = [...selectedColumns];
                                            newCols[idx] = val;
                                            setSelectedColumns(newCols);
                                        }}
                                        value={col}
                                    />
                                    <Button 
                                        type="text" 
                                        icon={<DeleteOutlined />} 
                                        onClick={() => {
                                            setSelectedColumns(prev => prev.filter((_, i) => i !== idx));
                                        }} 
                                        style={{ marginLeft: 8 }}
                                    />
                                </div>
                            ))}
                            <Button
                                type="dashed"
                                icon={<PlusOutlined />}
                                style={{ marginTop: 8 }}
                                onClick={() => setSelectedColumns(prev => [...prev, null])}
                            >
                                Add another column
                            </Button>

                            {selectedType === 'associate' && (
                                <>
                                    <br />
                                    <br />
                                    <Typography.Text style={{ marginTop: 15 }}>
                                        Select worksheet to match against
                                    </Typography.Text>
                                    <br/>
                                    <Select 
                                        style={{ width: '100%', marginTop: 8 }}
                                        showSearch
                                        allowClear
                                        filterOption={false}
                                        options={filteredWorksheetOptions.map((o) => ({
                                            ...o,
                                            label: o.label,
                                            disabled: !o.last_indexed_at
                                        }))}
                                        notFoundContent={!worksheetOptions ? <LoadingOutlined /> : 'No results'}
                                        optionRender={(option) => {
                                            return (
                                                <Col>
                                                    <Row>
                                                        <Typography.Text
                                                            style={{ fontSize: 14, color: !option.data.last_indexed_at ? '#918d8d' : 'black'}}
                                                        >{option.label}</Typography.Text>
                                                    </Row>
                                                    <Typography.Text
                                                        style={{ fontSize: 12, color: '#918d8d' }}
                                                    >
                                                        {'[AI trained: ' + (option.data.last_indexed_at ? formatTimeAgo(option.data.last_indexed_at) : 'never') + ']'}
                                                    </Typography.Text>
                                                </Col>
                                            )
                                        }}
                                        onSearch={(val) => {
                                            setFilteredWorksheetOptions(worksheetOptions.filter((o) => o.label.toLowerCase().includes(val.toLowerCase())));
                                        }}
                                        onClear={() => setFilteredWorksheetOptions(worksheetOptions)}
                                        onChange={selectWorksheet}
                                        value={selectedWorksheet || undefined}
                                        placeholder="Select a worksheet"
                                    />
                                    <br />
                                    <br />
                                    { selectedWorksheet && !worksheetColumnOptions && <LoadingOutlined /> }
                                    
                                    { selectedWorksheet && worksheetColumnOptions && (
                                        <>
                                            <Typography.Text style={{ marginTop: 15 }}>
                                                Select column from {
                                                    worksheetOptions.find((o) => o.value === selectedWorksheet)?.label
                                                } to pull values from
                                            </Typography.Text>
                                            <br/>
                                            <Select 
                                                style={{ width: '100%', marginTop: 8 }}
                                                options={worksheetColumnOptions}
                                                loading={!worksheetColumnOptions}
                                                onChange={setSelectedWorksheetColumn}
                                                value={safeWorksheetColumnValue}
                                                placeholder="Select a column"
                                            />
                                            {/* --- Advanced Options --- */}
                                            <Collapse ghost defaultActiveKey={[]} style={{ marginTop: 16 }}>
                                                <Collapse.Panel header="Advanced Options" key="1">
                                                    <Typography.Text style={{ marginTop: 15 }}>
                                                        Row match AI prompt
                                                    </Typography.Text>
                                                    <br />
                                                    <TextArea 
                                                        style={{ width: '100%', marginTop: 8 }}
                                                        placeholder="Which retail chain does this store location belong to?"
                                                        rows={2}
                                                        value={newPrompt}
                                                        onChange={(e) => setNewPrompt(e.target.value)}
                                                    />
                                                    <br />
                                                    <br />
                                                    <Checkbox 
                                                        checked={leaveBlank}
                                                        onChange={(e) => setLeaveBlank(e.target.checked)}
                                                    >
                                                        Leave match blank if AI unsure
                                                    </Checkbox>
                                                </Collapse.Panel>
                                            </Collapse>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {/* ============= TRANSFORM ============= */}
                    {selectedType && enrichmentConfigs[selectedType].category === 'Transform' && (
                        <>
                            <Typography.Text>Destination column name</Typography.Text>
                            <br />
                            <Input
                                style={{ width: '100%', marginTop: 8 }}
                                value={newColumnName}
                                onChange={(e) => setNewColumnName(e.target.value)}
                            />
                            <br />
                            <br />
                            <Typography.Title level={5} style={{ marginTop: 16 }}>
                                Setup Inputs
                            </Typography.Title>

                            <Typography.Text>Select an input column to transform</Typography.Text>
                            <br />
                            <Select
                                placeholder="Select a column"
                                style={{ width: '100%', marginTop: 8 }}
                                options={localColumnOptions}
                                onChange={(val) => setSelectedColumns([val])}
                                value={selectedColumns[0] || undefined}
                            />

                            <br />
                            <br />
                            <Typography.Text>AI Prompt</Typography.Text>
                            <br />
                            <TextArea
                                style={{ width: '100%', marginTop: 8 }}
                                rows={4}
                                placeholder="Enter an AI prompt to transform the input column data..."
                                value={newPrompt}
                                onChange={(e) => setNewPrompt(e.target.value)}
                            />
                        </>
                    )}

                    {/* ============= LOCATIONS ============= */}
                    {selectedType && enrichmentConfigs[selectedType].category === 'Locations' && (
                        <>
                            <Typography.Text>New column name</Typography.Text>
                            <br />
                            <Input
                                style={{ width: '100%', marginTop: 8 }}
                                value={newColumnName}
                                onChange={(e) => setNewColumnName(e.target.value)}
                            />
                            <br />
                            <br />

                            <Typography.Title level={5} style={{ marginTop: 16 }}>
                                Setup Inputs for Quantity Sold
                            </Typography.Title>
                            <Typography.Text>Select product(s)</Typography.Text>
                            <br />
                            <Select
                                mode="multiple"
                                style={{ width: '100%', marginTop: 8 }}
                                value={selectedProductsLoc}
                                onChange={(vals) => {
                                    if (vals.length === 0) setSelectedProductsLoc(['*']);
                                    // if All is newly selected, clear the rest
                                    else if (vals.includes('*') && !selectedProductsLoc.includes('*')) {
                                        setSelectedProductsLoc(['*']);
                                    } else if (selectedProductsLoc.includes('*') && (!vals.includes('*') || vals.length > 1)) {
                                        setSelectedProductsLoc(vals.filter((v) => v !== '*'));
                                    }
                                    else setSelectedProductsLoc(vals);
                                }}
                                // search by lowercase
                                filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                                options={[
                                    { label: '(All)', value: '*' },
                                    ...(products || []).map((p) => ({ label: p.name, value: p.uuid }))
                                ]}
                            />
                            <br />
                            <br />
                            <Typography.Text>Sum date range(s)</Typography.Text>
                            <br />
                            {loadingDateRangesLoc ? (
                                <LoadingOutlined style={{ marginLeft: 8 }} />
                            ) : (
                                <Select
                                    mode="multiple"
                                    style={{ width: '100%', marginTop: 8 }}
                                    value={selectedDateRangesLoc}
                                    onChange={setSelectedDateRangesLoc}
                                    options={dateRangesLoc.map((dr) => ({ label: (
                                        // parse into format Jan 1st, 2021 - Jan 31st, 2021
                                        dr.split(' - ').map((d) => {
                                            const dobj = dayjs(d);
                                            return dobj.format('MMM D, YYYY');
                                        }).join(' - ')
                                    ), value: dr }))}
                                />
                            )}
                        </>
                    )}
                </Col>
            </Row>
        </Modal>
    );
};

export default EnrichmentFlow;