import React, { useState, useMemo, useEffect } from "react";
import {
  Modal,
  Tabs,
  Typography,
  Select,
  Upload,
  Button,
  Divider,
  Row,
  Col,
  Checkbox,
  message,
  Spin,
  Collapse,
  Radio
} from "antd";
import {
  UploadOutlined,
  LoadingOutlined,
  DeleteOutlined,
  ArrowRightOutlined,
  PlayCircleOutlined,
  InboxOutlined,
  FontSizeOutlined,
  FieldBinaryOutlined,
  SyncOutlined,
  CloudDownloadOutlined
} from "@ant-design/icons";
import * as XLSX from "xlsx";
import { fetchWorksheetData } from "../../endpoints/fetchWorksheetData";
import { uuid4 } from "../utils";

const { Dragger } = Upload;
const { TabPane } = Tabs;
const { Panel } = Collapse;

/**
 * Return an icon for the given column_type
 */
function getColumnIcon(columnType) {
  if (columnType === "text") {
    return <FontSizeOutlined style={{ color: "#918d8d", marginRight: 4 }} />;
  } else if (columnType === "number") {
    return <FieldBinaryOutlined style={{ color: "#918d8d", marginRight: 4 }} />;
  } else if (columnType === "enrichment") {
    return <PlayCircleOutlined style={{ color: "#918d8d", marginRight: 4 }} />;
  }
  return null;
}

/**
 * Helper for rendering a column label with an icon
 */
function getColumnLabel(col) {
  const icon = getColumnIcon(col.column_type);
  return (
    <span>
      {icon}
      {col.name}
    </span>
  );
}

/**
 * Detect which remote columns have gone missing for a given Worksheet item
 */
function getMissingWorksheetColumns(item, remoteCols, localColumns) {
  if (!remoteCols?.length) return [];

  const remoteKeys = remoteCols.map((rc) => rc.key);
  const missingLines = [];

  Object.entries(item.mapping || {}).forEach(([localKey, remoteKey]) => {
    if (remoteKey !== "leave_blank" && !remoteKeys.includes(remoteKey)) {
      // We can't get the old remote name easily, so we display the remoteKey as "a"
      // and the local column name as "b".
      const local = localColumns.find((x) => x.key === localKey);
      const localName = local ? local.name : localKey;
      missingLines.push(`column missing from other woksheet, previously held "${localName}"`);
    } 
    // check if the local key is missing
    else if (!localColumns.some((lc) => lc.key === localKey)) {
      const remoteName = remoteCols.find((x) => x.key === remoteKey)?.name || remoteKey;
      missingLines.push(`column missing from current worksheet, previously held "${remoteName}"`);
    }
  });

  return missingLines;
}

/**
 * Detect which local columns have gone missing for a given Location item
 */
function getMissingLocationColumns(item, localColumns) {
  const missingLines = [];
  Object.entries(item.mapping || {}).forEach(([locColName, localKey]) => {
    if (localKey !== "leave_blank" && !localColumns.some((lc) => lc.key === localKey)) {
      missingLines.push(`column missing, previously held "${locColName}"`);
    }
  });
  return missingLines;
}

/**
 * The unified ImportFlow component
 */
const ImportFlow = ({
  open,
  onClose,
  userData,
  currentWorksheetId,
  columns,
  importConfig,
  onImportRows
}) => {
  const [activeTab, setActiveTab] = useState("files");
  const [importing, setImporting] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  // Master config for existing + newly added sources
  const [localImportConfig, setLocalImportConfig] = useState(
    importConfig || { importedWorksheets: [] }
  );
  const importedWorksheets = localImportConfig.importedWorksheets || [];

  const [expandedKeys, setExpandedKeys] = useState([]);

  // Single global wipe option
  const [wipeExistingBeforeImport, setWipeExistingBeforeImport] = useState(true);

  // Filter out placeholders/enrichment from local columns
  const localColumns = useMemo(() => {
    return columns.filter(
      (c) =>
        c.key !== "rowIndex" &&
        c.key !== "addColumn" &&
        c.column_type !== "enrichment"
    );
  }, [columns]);

  /**************************************************
   * A) WORKSHEET IMPORT TAB
   **************************************************/
  const [loadingWorksheets, setLoadingWorksheets] = useState(false);
  const [worksheetOptions, setWorksheetOptions] = useState([]);
  const [worksheetDict, setWorksheetDict] = useState({});
  const [selectedWorksheet, setSelectedWorksheet] = useState(null);
  const [remoteColumns, setRemoteColumns] = useState(null);
  const [columnMapping, setColumnMapping] = useState({});

  // Fetch list of worksheets
  useEffect(() => {
    if (!userData?.accessToken) return;

    const loadWorksheetList = async () => {
      setLoadingWorksheets(true);
      const body = { action: "list_worksheets" };
      const data = await fetchWorksheetData(userData.accessToken, body);
      if (data.error) {
        console.error("Error fetching worksheets", data.error);
        setWorksheetOptions([]);
      } else {
        const dict = {};
        (data.metadata || []).forEach((o) => {
          dict[o.id] = { name: o.name, folder: o.folder };
        });

        const options = (data.metadata || [])
          .filter((o) => !o.isFolder && o.id !== currentWorksheetId)
          .map((o) => ({
            label: (o.folder ? o.folder + "/" : "") + o.name,
            value: o.id,
            folder: o.folder
          }));

        setWorksheetDict(dict);
        setWorksheetOptions(options);
      }
      setLoadingWorksheets(false);
    };

    loadWorksheetList();
  }, [userData, currentWorksheetId]);

  // Load columns for the selected worksheet & do auto-mapping
  useEffect(() => {
    if (!selectedWorksheet) {
      setRemoteColumns(null);
      return;
    }
    setRemoteColumns(null);

    const folderOption = worksheetOptions.find((o) => o.value === selectedWorksheet);
    const folder = folderOption?.folder || "";

    const loadCols = async () => {
      const body = {
        action: "list_worksheet_columns",
        worksheet_id: selectedWorksheet,
        folder
      };
      const data = await fetchWorksheetData(userData.accessToken, body);
      if (data.error) {
        console.error("Error fetching remote columns", data.error);
        setRemoteColumns([]);
      } else {
        const colArr = data.cols || [];
        // Attempt naive auto-map by name
        const newMapping = {};
        localColumns.forEach((localCol) => {
          const match = colArr.find(
            (rc) => rc.name.toLowerCase() === localCol.name.toLowerCase()
          );
          newMapping[localCol.key] = match ? match.key : "leave_blank";
        });
        setColumnMapping(newMapping);
        setRemoteColumns(colArr);
      }
    };
    loadCols();
  }, [selectedWorksheet, worksheetOptions, userData, localColumns]);

  // Construct an item to represent the newly selected worksheet
  const buildWorksheetImportItem = () => {
    if (!selectedWorksheet) {
      setErrorMsg("No worksheet selected!");
      return null;
    }
    if (!remoteColumns || !remoteColumns.length) {
      setErrorMsg("No columns found (or not loaded) in the selected worksheet.");
      return null;
    }
    const wsName = worksheetDict[selectedWorksheet]?.name || `ID: ${selectedWorksheet}`;
    return {
      type: "worksheet",
      worksheetId: selectedWorksheet,
      worksheetName: wsName,
      folder: worksheetDict[selectedWorksheet]?.folder,
      mapping: { ...columnMapping }
    };
  };

  const renderWorksheetImportTab = () => {
    return (
      <div style={{ marginTop: 16 }}>
        <Typography.Text>Select a worksheet to import from</Typography.Text>
        <br />
        {loadingWorksheets ? (
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            style={{ marginTop: 8 }}
          />
        ) : (
          <Select
            style={{ width: "100%", marginTop: 8 }}
            placeholder="Select a worksheet"
            options={worksheetOptions}
            value={selectedWorksheet || undefined}
            onChange={(val) => {
              setSelectedWorksheet(val);
              setErrorMsg(null);
            }}
          />
        )}

        {selectedWorksheet && remoteColumns === null && (
          <div style={{ marginTop: 16 }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          </div>
        )}

        {/* If columns loaded, show column mapping UI */}
        {selectedWorksheet && remoteColumns && (
          <div style={{ marginTop: 16 }}>
            <Row style={{ marginBottom: 8 }}>
              <Col flex="none" style={{ width: 220, fontWeight: "bold" }}>
                Map Columns
              </Col>
              <Col flex="none" style={{ width: 20 }} />
              <Col flex="auto" style={{ fontWeight: "bold" }} />
            </Row>
            {localColumns.map((localCol) => {
              const remoteOpts = remoteColumns.map((rc) => ({
                label: (
                  <span>
                    {getColumnIcon(rc.column_type)}
                    {rc.name}
                  </span>
                ),
                value: rc.key
              }));
              const combinedOptions = [
                { label: "Leave blank", value: "leave_blank" },
                ...remoteOpts
              ];

              // If the currentVal is missing from remoteOpts, just fallback to "leave_blank"
              const currentVal = columnMapping[localCol.key] || "leave_blank";
              const isMissingOption =
                currentVal !== "leave_blank" &&
                !remoteOpts.some((opt) => opt.value === currentVal);

              const finalVal = isMissingOption ? "leave_blank" : currentVal;

              return (
                <Row key={localCol.key} style={{ marginBottom: 8 }}>
                  <Col flex="auto" style={{ marginRight: 15 }}>
                    <Select
                      style={{ width: "100%" }}
                      options={combinedOptions}
                      value={finalVal}
                      onChange={(val) => {
                        setColumnMapping((prev) => ({
                          ...prev,
                          [localCol.key]: val
                        }));
                      }}
                    />
                  </Col>
                  <Col flex="none" style={{ width: 20, marginRight: 15 }}>
                    <ArrowRightOutlined />
                  </Col>
                  <Col flex="none" style={{ width: 220 }}>
                    {getColumnLabel(localCol)}
                  </Col>
                </Row>
              );
            })}
          </div>
        )}

        <Divider />

        <Row style={{ marginTop: 16, justifyContent: "flex-end" }}>
          <Col>
            <Checkbox
              checked={wipeExistingBeforeImport}
              onChange={(e) => setWipeExistingBeforeImport(e.target.checked)}
            >
              Wipe existing rows before import
            </Checkbox>
          </Col>
        </Row>

        <Row style={{ marginTop: 16, textAlign: "right" }}>
          <Col flex="auto" />
          <Col>
            <Button
              style={{ marginRight: 8 }}
              onClick={() => {
                setErrorMsg(null);
                const item = buildWorksheetImportItem();
                if (!item) return;
                handleImportOnce(item);
              }}
              loading={importing}
              icon={<CloudDownloadOutlined />}
            >
              Import Once
            </Button>
            <Button
              type="primary"
              onClick={async () => {
                setErrorMsg(null);
                const item = buildWorksheetImportItem();
                if (!item) return;
                const newImportedWorksheets = [...(importedWorksheets || []), item];
                setLocalImportConfig((prev) => ({
                  ...prev,
                  importedWorksheets: newImportedWorksheets
                }));
                setSelectedWorksheet(null);
                setRemoteColumns(null);
                setColumnMapping({});
                setImporting(true);
                try {
                  await onImportRows(newImportedWorksheets, wipeExistingBeforeImport);
                } catch (err) {
                  console.error("Import all error", err);
                  setErrorMsg(String(err));
                } finally {
                  setImporting(false);
                }
              }}
              loading={importing}
              icon={<SyncOutlined />}
            >
              Add Import &amp; Sync
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  /**************************************************
   * B) FILE IMPORT TAB
   **************************************************/
  const [fileList, setFileList] = useState([]);
  const [fileMode, setFileMode] = useState("use"); // "use" or "map"
  const [fileColumns, setFileColumns] = useState([]);
  const [fileColumnMapping, setFileColumnMapping] = useState({});

  const parseFileForColumns = async (fileObj) => {
    try {
      const arrayBuffer = await fileObj.arrayBuffer();
      const wb = XLSX.read(new Uint8Array(arrayBuffer), { type: "array" });
      const sheetName = wb.SheetNames[0];
      const sheet = wb.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      if (sheetData.length < 1) {
        setErrorMsg("No header row found in the file.");
        setFileColumns([]);
        return;
      }
      const headerRow = sheetData[0];
      if (!headerRow || headerRow.length === 0) {
        setErrorMsg("No valid header row found.");
        setFileColumns([]);
        return;
      }

      const newCols = [];
      for (let i = 0; i < headerRow.length; i++) {
        const fileColName = headerRow[i];
        newCols.push({
          index: i,
          name: fileColName ? String(fileColName) : `Untitled`,
          column_type: "text",
          key: uuid4()
        });
      }

      const mapObj = {};
      newCols.forEach((fc) => {
        const localMatch = localColumns.find(
          (lc) => lc.name.toLowerCase() === fc.name.toLowerCase()
        );
        mapObj[fc.index] = localMatch ? localMatch.key : "leave_blank";
      });

      setFileColumns(newCols);
      setFileColumnMapping(mapObj);
      setErrorMsg(null);
    } catch (err) {
      console.error("Error parsing file columns:", err);
      setErrorMsg(`Error parsing file columns: ${err.message || err}`);
      setFileColumns([]);
    }
  };

  // Whenever fileList changes, parse if there's exactly 1
  useEffect(() => {
    if (fileList.length === 1) {
      parseFileForColumns(fileList[0].originFileObj);
    } else {
      setFileColumns([]);
      setFileColumnMapping({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const handleLocalFileImport = async () => {
    if (fileList.length !== 1) {
      setErrorMsg("Please select exactly one CSV or Excel file.");
      return;
    }
    setImporting(true);
    setErrorMsg(null);

    try {
      const fileObj = fileList[0].originFileObj;
      const arrayBuffer = await fileObj.arrayBuffer();
      const wb = XLSX.read(new Uint8Array(arrayBuffer), { type: "array" });
      const sheetName = wb.SheetNames[0];
      const sheet = wb.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

      if (sheetData.length < 1) {
        setErrorMsg("No header row found in the file.");
        return;
      }
      if (sheetData.length < 2) {
        setErrorMsg("File must have at least one data row.");
        return;
      }
      const [headerRow, ...dataRows] = sheetData;
      if (!headerRow || headerRow.length === 0) {
        setErrorMsg("No valid header row found.");
        return;
      }

      if (fileMode === "use") {
        // "Use file columns" => brand new columns
        const cols = fileColumns;
        const rows = dataRows.map((rowArr, idx) => {
          const rowObj = { id: idx };
          cols.forEach((c, colIdx) => {
            rowObj[c.key] = rowArr[colIdx];
          });
          return rowObj;
        });
        await onImportRows([], wipeExistingBeforeImport, { rows, cols });
        setFileList([]);
      } else {
        // "map" => partial mapping
        const mappedCols = Object.entries(fileColumnMapping).filter(
          ([, val]) => val !== "leave_blank"
        );
        if (!mappedCols.length) {
          setErrorMsg("No file columns mapped to local columns.");
          return;
        }

        const localColsByKey = {};
        columns.forEach((lc) => {
          if (lc.key !== "rowIndex" && lc.key !== "addColumn") {
            localColsByKey[lc.key] = lc;
          }
        });

        const newRows = dataRows.map((rowArr, idx) => {
          const rowObj = { id: idx };
          mappedCols.forEach(([fileIndexStr, localKey]) => {
            const fileIndexNum = Number(fileIndexStr);
            if (localColsByKey[localKey]) {
              rowObj[localKey] = rowArr[fileIndexNum];
            }
          });
          return rowObj;
        });

        // replicate existing columns so we don't remove any
        const overrideCols = columns
          .filter((c) => c.key !== "rowIndex" && c.key !== "addColumn")
          .map((col) => ({
            key: col.key,
            name: col.name,
            column_type: col.column_type
          }));

        const finalRows = newRows.map((r, idx) => {
          const fullRow = { id: idx };
          overrideCols.forEach((col) => {
            fullRow[col.key] = r[col.key] || "";
          });
          return fullRow;
        });

        await onImportRows([], wipeExistingBeforeImport, {
          rows: finalRows,
          cols: overrideCols
        });
        setFileList([]);
      }
    } catch (err) {
      console.error("Error parsing file:", err);
      setErrorMsg(`Error parsing file: ${err.message || err}`);
    } finally {
      setImporting(false);
    }
  };

  const handleFileColumnChange = (fileIndex, localKey) => {
    setFileColumnMapping((prev) => ({ ...prev, [fileIndex]: localKey }));
  };

  const renderMapFileColumnsTable = () => {
    if (!fileColumns.length) {
      return <div style={{ marginTop: 8 }}>No columns found in file.</div>;
    }
    return (
      <div style={{ marginTop: 8, padding: "8px 12px", border: "1px solid #f0f0f0" }}>
        <Row style={{ marginBottom: 8, fontWeight: "bold" }}>
          <Col span={10}>File Column</Col>
          <Col span={2} />
          <Col span={12}>Map to Worksheet Column</Col>
        </Row>
        {fileColumns.map((fc) => {
          const currentVal = fileColumnMapping[fc.index] || "leave_blank";
          const isMissingOption =
            currentVal !== "leave_blank" &&
            !localColumns.some((c) => c.key === currentVal);
          // fallback to leave_blank if missing
          const displayVal = isMissingOption ? "leave_blank" : currentVal;

          const localOptions = [
            { label: "Leave blank", value: "leave_blank" },
            ...localColumns.map((lc) => ({
              label: getColumnLabel(lc),
              value: lc.key
            }))
          ];

          return (
            <Row key={fc.index} style={{ marginBottom: 8 }}>
              <Col span={10}>
                {getColumnIcon(fc.column_type)}
                {fc.name}
              </Col>
              <Col span={2} style={{ textAlign: "center" }}>
                <ArrowRightOutlined />
              </Col>
              <Col span={12}>
                <Select
                  style={{ width: "100%" }}
                  value={displayVal}
                  onChange={(val) => {
                    setFileColumnMapping((prev) => ({
                      ...prev,
                      [fc.index]: val
                    }));
                  }}
                  options={localOptions}
                />
              </Col>
            </Row>
          );
        })}
      </div>
    );
  };

  const renderFileImportTab = () => {
    const draggerProps = {
      name: "file",
      multiple: false,
      fileList,
      maxCount: 1,
      beforeUpload: (file) => {
        if (!/\.(csv|xlsx|xls)$/i.test(file.name)) {
          message.error("Only CSV or Excel files are allowed!");
          return Upload.LIST_IGNORE;
        }
        return false;
      },
      onChange: ({ fileList: newList }) => setFileList(newList),
      style: {
        padding: 5,
        border: "1px dashed #d9d9d9",
        borderRadius: 4
      }
    };

    return (
      <div style={{ marginTop: 16 }}>
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag CSV/Excel file</p>
          <p className="ant-upload-hint">First row must be header</p>
        </Dragger>

        {fileList.length === 1 && (
          <>
            <Divider />
            <Radio.Group
              value={fileMode}
              onChange={(e) => setFileMode(e.target.value)}
              style={{ marginBottom: 12 }}
            >
              <Radio value="use">Use file columns</Radio>
              <Radio value="map">Map file columns</Radio>
            </Radio.Group>

            {fileMode === "use" && (
              <div
                style={{ marginBottom: 16, padding: "8px 12px", border: "1px solid #f0f0f0" }}
              >
                {fileColumns.length > 0 ? (
                  <div style={{ marginTop: 8 }}>
                    {fileColumns.map((fc, idx) => (
                      <div key={idx} style={{ marginBottom: 4 }}>
                        {getColumnIcon(fc.column_type)}
                        <span style={{ marginLeft: 4 }}>{fc.name}</span>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div style={{ marginTop: 8 }}>No columns detected yet.</div>
                )}
              </div>
            )}

            {fileMode === "map" && renderMapFileColumnsTable()}
          </>
        )}

        <Divider />
        <Row style={{ marginTop: 16, justifyContent: "flex-end" }}>
          <Col>
            <Checkbox
              checked={wipeExistingBeforeImport}
              onChange={(e) => setWipeExistingBeforeImport(e.target.checked)}
            >
              Wipe existing rows before import
            </Checkbox>
          </Col>
        </Row>

        <Row style={{ marginTop: 16, textAlign: "right" }}>
          <Col flex="auto" />
          <Col>
            <Button
              type="primary"
              onClick={handleLocalFileImport}
              icon={<CloudDownloadOutlined />}
              loading={importing}
            >
              Import Once
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  /**************************************************
   * C) LOCATION IMPORT TAB
   **************************************************/
  const baseLocationCols = useMemo(
    () => [
      { key: uuid4(), name: "Location Name", column_type: "text" },
      { key: uuid4(), name: "Address Line 1", column_type: "text" },
      { key: uuid4(), name: "Address Line 2", column_type: "text" },
      { key: uuid4(), name: "City", column_type: "text" },
      { key: uuid4(), name: "State", column_type: "text" },
      { key: uuid4(), name: "Zip", column_type: "text" }
    ],
    []
  );

  const [customLocationCols, setCustomLocationCols] = useState([]);
  const locationAllColumns = useMemo(() => {
    return [...baseLocationCols, ...customLocationCols];
  }, [baseLocationCols, customLocationCols]);

  const [locationMappingMode, setLocationMappingMode] = useState("use");
  const [locationColumnMapping, setLocationColumnMapping] = useState({});

  // If user toggles "map", ensure we have an entry for each location col
  useEffect(() => {
    if (locationMappingMode === "map") {
      const newMapping = { ...locationColumnMapping };
      let changed = false;
      for (const locCol of locationAllColumns) {
        if (!newMapping[locCol.name]) {
          newMapping[locCol.name] = "leave_blank";
          changed = true;
        }
      }
      // remove any leftover mappings
      for (const k of Object.keys(newMapping)) {
        if (!locationAllColumns.find((lc) => lc.name === k)) {
          delete newMapping[k];
          changed = true;
        }
      }
      if (changed) {
        setLocationColumnMapping(newMapping);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationAllColumns, locationMappingMode]);

  const removeLocationColumn = (colKey) => {
    // Only remove from custom columns, leaving base columns intact
    const newCustom = customLocationCols.filter((c) => c.key !== colKey);
    setCustomLocationCols(newCustom);

    // remove from mapping
    const removedCol = locationAllColumns.find((lc) => lc.key === colKey);
    if (removedCol) {
      const newMap = { ...locationColumnMapping };
      delete newMap[removedCol.name];
      setLocationColumnMapping(newMap);
    }
  };

  const renderLocationMappingUI = () => {
    return (
      <div style={{ marginTop: 16 }}>
        {locationAllColumns.map((locCol) => {
          const isBase = baseLocationCols.some((bc) => bc.key === locCol.key);

          if (locationMappingMode === "map") {
            const currentVal = locationColumnMapping[locCol.name] || "leave_blank";
            const isMissingOption =
              currentVal !== "leave_blank" && !localColumns.some((lc) => lc.key === currentVal);

            const finalVal = isMissingOption ? "leave_blank" : currentVal;

            const localOptions = [
              { label: "Leave blank", value: "leave_blank" },
              ...localColumns.map((lc) => ({
                label: lc.name,
                value: lc.key
              }))
            ];

            return (
              <Row key={locCol.key} style={{ marginBottom: 8 }}>
                <Col flex="none" style={{ width: 220 }}>
                  {getColumnIcon(locCol.column_type)}
                  {locCol.name}
                </Col>
                <Col
                  flex="none"
                  style={{ width: 20, marginRight: 15, marginLeft: 15 }}
                >
                  <ArrowRightOutlined />
                </Col>
                <Col flex="auto" style={{ marginRight: 15 }}>
                  <Select
                    style={{ width: "100%" }}
                    value={finalVal}
                    onChange={(val) =>
                      setLocationColumnMapping((prev) => ({
                        ...prev,
                        [locCol.name]: val
                      }))
                    }
                    options={localOptions}
                  />
                </Col>
                <Col flex="none" style={{ width: 40, textAlign: "right" }}>
                  {!isBase && (
                    <Button
                      icon={<DeleteOutlined />}
                      danger
                      onClick={() => removeLocationColumn(locCol.key)}
                      style={{ marginLeft: 8 }}
                    />
                  )}
                </Col>
              </Row>
            );
          }

          // locationMappingMode === 'use'
          return (
            <Row key={locCol.key} style={{ marginBottom: 8 }}>
              <Col flex="none" style={{ marginRight: 10 }}>
                {getColumnIcon(locCol.column_type)}
                {locCol.name}
              </Col>
              <Col flex="auto" />
              <Col flex="none" style={{ width: 40, textAlign: "right" }}>
                {!isBase && (
                  <Button
                    icon={<DeleteOutlined />}
                    danger
                    onClick={() => removeLocationColumn(locCol.key)}
                    style={{ marginLeft: 8 }}
                  />
                )}
              </Col>
            </Row>
          );
        })}
      </div>
    );
  };

  const handleLocationImportOnce = async () => {
    setErrorMsg(null);
    setImporting(true);
    try {
      const item = {
        type: "location",
        locationColumns: locationAllColumns,
        mappingMode: locationMappingMode,
        mapping: { ...locationColumnMapping }
      };
      await handleImportOnce(item);
    } catch (err) {
      setErrorMsg(String(err));
    } finally {
      setImporting(false);
    }
  };

  const handleLocationAddAndImportAll = async () => {
    setErrorMsg(null);

    const item = {
      type: "location",
      locationColumns: locationAllColumns,
      mappingMode: locationMappingMode,
      mapping: { ...locationColumnMapping }
    };
    const newImportedWorksheets = [...(importedWorksheets || []), item];
    setLocalImportConfig((prev) => ({
      ...prev,
      importedWorksheets: newImportedWorksheets
    }));
    setImporting(true);
    try {
      await onImportRows(newImportedWorksheets, wipeExistingBeforeImport);
    } catch (err) {
      setErrorMsg(String(err));
    } finally {
      setImporting(false);
    }
  };

  const renderLocationImportTab = () => {
    return (
      <div style={{ marginTop: 16 }}>
        <Radio.Group
          style={{ marginBottom: 16 }}
          value={locationMappingMode}
          onChange={(e) => setLocationMappingMode(e.target.value)}
        >
          <Radio value="use">Use location columns</Radio>
          <Radio value="map">Map to existing columns</Radio>
        </Radio.Group>

        {renderLocationMappingUI()}

        <Divider />
        <Row style={{ marginTop: 16, justifyContent: "flex-end" }}>
          <Col>
            <Checkbox
              checked={wipeExistingBeforeImport}
              onChange={(e) => setWipeExistingBeforeImport(e.target.checked)}
            >
              Wipe existing rows before import
            </Checkbox>
          </Col>
        </Row>

        <Row justify="end" style={{ marginTop: 16 }}>
          <Col>
            <Button
              style={{ marginRight: 8 }}
              onClick={handleLocationImportOnce}
              icon={<CloudDownloadOutlined />}
              loading={importing}
            >
              Import Once
            </Button>
            <Button
              icon={<SyncOutlined />}
              type="primary"
              disabled={locationMappingMode === "use"}
              onClick={handleLocationAddAndImportAll}
              loading={importing}
            >
              Add Import &amp; Sync
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  /**************************************************
   * D) EXISTING "WORKSHEET SOURCES" TAB
   **************************************************/
  const [existingRemoteCols, setExistingRemoteCols] = useState({});

  const autoAddNewLocationColumns = (index, item) => {
    if (!item.mapping || !item.locationColumns) return;
    const newMapping = { ...item.mapping };
    let changed = false;

    item.locationColumns.forEach((locCol) => {
      if (!newMapping[locCol.name]) {
        newMapping[locCol.name] = "leave_blank";
        changed = true;
      }
    });
    for (const colName of Object.keys(newMapping)) {
      if (!item.locationColumns.find((lc) => lc.name === colName)) {
        delete newMapping[colName];
        changed = true;
      }
    }

    if (changed) {
      setLocalImportConfig((prev) => {
        const newArr = [...prev.importedWorksheets];
        newArr[index] = { ...newArr[index], mapping: newMapping };
        return { ...prev, importedWorksheets: newArr };
      });
    }
  };

  const autoAddNewLocalColumns = (index, item) => {
    if (!item.mapping) return;
    const newMapping = { ...item.mapping };
    let changed = false;

    for (const col of localColumns) {
      if (!newMapping[col.key]) {
        newMapping[col.key] = "leave_blank";
        changed = true;
      }
    }
    if (changed) {
      setLocalImportConfig((prev) => {
        const newArr = [...prev.importedWorksheets];
        newArr[index] = { ...newArr[index], mapping: newMapping };
        return { ...prev, importedWorksheets: newArr };
      });
    }
  };

  const handleExpandChange = async (expanded) => {
    setExpandedKeys(expanded);
    for (const key of expanded) {
      const index = Number(key);
      const item = importedWorksheets[index];
      if (!item) continue;

      if (item.type === "worksheet") {
        if (existingRemoteCols[item.worksheetId]) {
          autoAddNewLocalColumns(index, item);
          continue;
        }
        try {
          const folder = item.folder || "";
          const body = {
            action: "list_worksheet_columns",
            worksheet_id: item.worksheetId,
            folder
          };
          const data = await fetchWorksheetData(userData.accessToken, body);
          if (data.error) {
            console.error("Error fetching remote columns for existing import", data.error);
            setExistingRemoteCols((prev) => ({ ...prev, [item.worksheetId]: [] }));
          } else {
            setExistingRemoteCols((prev) => ({
              ...prev,
              [item.worksheetId]: data.cols || []
            }));
          }
        } catch (err) {
          console.error("Error fetching remote columns for existing import", err);
        } finally {
          autoAddNewLocalColumns(index, item);
        }
      } else if (item.type === "location") {
        autoAddNewLocationColumns(index, item);
      }
    }
  };

  const handleExistingMappingChange = (itemIndex, newMapping) => {
    setLocalImportConfig((prev) => {
      const newArr = [...prev.importedWorksheets];
      newArr[itemIndex] = { ...newArr[itemIndex], mapping: newMapping };
      return { ...prev, importedWorksheets: newArr };
    });
  };

  const handleExistingLocationMappingChange = (itemIndex, newMapping) => {
    setLocalImportConfig((prev) => {
      const newArr = [...prev.importedWorksheets];
      newArr[itemIndex] = { ...newArr[itemIndex], mapping: newMapping };
      return { ...prev, importedWorksheets: newArr };
    });
  };

  const handleRemoveImportedWorksheet = (index) => {
    setLocalImportConfig((prev) => {
      const newArr = [...prev.importedWorksheets];
      newArr.splice(index, 1);
      return { ...prev, importedWorksheets: newArr };
    });
  };

  const handleImportAll = async (list) => {
    const arr = list || importedWorksheets;
    if (!arr?.length) return;
    setImporting(true);
    setErrorMsg(null);

    try {
      await onImportRows(arr, wipeExistingBeforeImport);
    } catch (err) {
      console.error("Import all error", err);
      setErrorMsg(String(err));
    } finally {
      setImporting(false);
    }
  };

  const handleImportOnce = async (item, overrideData = {}) => {
    if (!item) return;
    setImporting(true);
    setErrorMsg(null);
    try {
      await onImportRows([item], wipeExistingBeforeImport, { ...overrideData }, { ephemeral: true });
    } catch (err) {
      console.error("Import once error", err);
      setErrorMsg(String(err));
    } finally {
      setImporting(false);
    }
  };

  const renderWorksheetMappingTable = (
    itemIndex,
    currentMapping,
    updateMapping,
    remoteCols
  ) => {
    if (!remoteCols?.length) {
      return <div>No remote columns found in selected worksheet.</div>;
    }

    const remoteOptions = remoteCols.map((rc) => ({
      label: (
        <span>
          {getColumnIcon(rc.column_type)}
          {rc.name}
        </span>
      ),
      value: rc.key
    }));
    const combinedOptions = [
      { label: "Leave blank", value: "leave_blank" },
      ...remoteOptions
    ];

    return (
      <div style={{ marginTop: 16 }}>
        <Row style={{ marginBottom: 8 }}>
          <Col flex="none" style={{ width: 220, fontWeight: "bold" }}>
            Map Columns
          </Col>
          <Col flex="none" style={{ width: 20 }} />
          <Col flex="auto" style={{ fontWeight: "bold" }} />
        </Row>
        {localColumns.map((localCol) => {
          const currentVal = currentMapping[localCol.key] || "leave_blank";
          const isMissingOption =
            currentVal !== "leave_blank" &&
            !remoteOptions.some((opt) => opt.value === currentVal);
          const finalVal = isMissingOption ? "leave_blank" : currentVal;

          return (
            <Row key={localCol.key} style={{ marginBottom: 8 }}>
              <Col flex="auto" style={{ marginRight: 15 }}>
                <Select
                  style={{ width: "100%" }}
                  options={combinedOptions}
                  value={finalVal}
                  onChange={(val) => {
                    const newMap = { ...currentMapping, [localCol.key]: val };
                    updateMapping(itemIndex, newMap);
                  }}
                />
              </Col>
              <Col flex="none" style={{ width: 20, marginRight: 15 }}>
                <ArrowRightOutlined />
              </Col>
              <Col flex="none" style={{ width: 220 }}>
                {getColumnLabel(localCol)}
              </Col>
            </Row>
          );
        })}
      </div>
    );
  };

  const renderLocationMappingTable = (
    itemIndex,
    currentMapping,
    updateMapping,
    locationCols
  ) => {
    if (!locationCols?.length) {
      return <div>No location columns found.</div>;
    }

    return (
      <div style={{ marginTop: 16 }}>
        <Row style={{ marginBottom: 8 }}>
          <Col flex="none" style={{ width: 220, fontWeight: "bold" }}>
            Location Column
          </Col>
          <Col flex="none" style={{ width: 20 }} />
          <Col flex="auto" style={{ fontWeight: "bold" }}>
            Map to Worksheet Column
          </Col>
        </Row>
        {locationCols.map((locCol) => {
          const currentVal = currentMapping[locCol.name] || "leave_blank";
          const isMissingOption =
            currentVal !== "leave_blank" &&
            !localColumns.some((lc) => lc.key === currentVal);
          const finalVal = isMissingOption ? "leave_blank" : currentVal;

          const localOptions = [
            { label: "Leave blank", value: "leave_blank" },
            ...localColumns.map((lc) => ({
              label: lc.name,
              value: lc.key
            }))
          ];

          return (
            <Row key={locCol.key} style={{ marginBottom: 8 }}>
              <Col flex="none" style={{ width: 220 }}>
                {getColumnIcon(locCol.column_type)}
                {locCol.name}
              </Col>
              <Col flex="none" style={{ width: 20, marginRight: 15, marginLeft: 15 }}>
                <ArrowRightOutlined />
              </Col>
              <Col flex="auto" style={{ marginRight: 15 }}>
                <Select
                  style={{ width: "100%" }}
                  value={finalVal}
                  options={localOptions}
                  onChange={(val) => {
                    const newMap = { ...currentMapping, [locCol.name]: val };
                    updateMapping(itemIndex, newMap);
                  }}
                />
              </Col>
            </Row>
          );
        })}
      </div>
    );
  };

  const renderDataSourcesTab = () => {
    return (
      <div style={{ marginTop: 16 }}>
        {importedWorksheets.length > 0 ? (
          <Collapse
            ghost
            activeKey={expandedKeys}
            onChange={handleExpandChange}
            accordion={false}
          >
            {importedWorksheets.map((item, index) => {
              let header;
              let mappedCols = 0;
              let totalCols = 0;
              let missingMessages = [];

              if (item.type === "worksheet") {
                totalCols = columns.filter(
                  (c) =>
                    c.key !== "rowIndex" &&
                    c.key !== "addColumn" &&
                    c.column_type !== "enrichment"
                ).length;

                mappedCols = Object.values(item.mapping || {}).filter(
                  (val) => val && val !== "leave_blank"
                ).length;

                const remoteCols = existingRemoteCols[item.worksheetId] || [];
                missingMessages = getMissingWorksheetColumns(item, remoteCols, localColumns);

                header = (
                  <>
                    <b>{item.worksheetName || `ID: ${item.worksheetId}`}</b>
                    <span style={{ marginLeft: 8 }}>
                      [ <span style={{ color: missingMessages.length ? "red" : "inherit" }}>
                          {mappedCols}
                        </span>{" "}
                        / {totalCols} columns ]
                    </span>
                  </>
                );
              } else if (item.type === "location") {
                const colCount = item.locationColumns?.length || 0;
                mappedCols = Object.values(item.mapping || {}).filter(
                  (val) => val && val !== "leave_blank"
                ).length;

                missingMessages = getMissingLocationColumns(item, localColumns);

                header = (
                  <>
                    <b>Location Source</b>{" "}
                    <span>
                      [ Mapped{" "}
                      <span style={{ color: missingMessages.length ? "red" : "inherit" }}>
                        {mappedCols}
                      </span>{" "}
                      / {colCount} columns ]
                    </span>
                  </>
                );
              } else {
                header = <b>File import</b>;
              }

              return (
                <Panel
                  key={index}
                  header={header}
                  extra={
                    <Button
                      icon={<DeleteOutlined />}
                      danger
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveImportedWorksheet(index);
                      }}
                    >
                      Remove
                    </Button>
                  }
                >
                  {missingMessages.length > 0 && (
                    <div style={{ marginBottom: 16, marginLeft: 40 }}>
                      {missingMessages.map((msg, i) => (
                        <Typography.Text type="danger" key={i} style={{ display: "block" }}>
                          {msg}
                        </Typography.Text>
                      ))}
                    </div>
                  )}

                  {item.type === "worksheet" && (
                    <div style={{ marginTop: 8, marginLeft: 40 }}>
                      {item.worksheetId && !existingRemoteCols[item.worksheetId] ? (
                        <Spin
                          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                        />
                      ) : (
                        renderWorksheetMappingTable(
                          index,
                          item.mapping || {},
                          handleExistingMappingChange,
                          existingRemoteCols[item.worksheetId] || []
                        )
                      )}
                    </div>
                  )}
                  {item.type === "location" && (
                    <div style={{ marginTop: 8, marginLeft: 40 }}>
                      {renderLocationMappingTable(
                        index,
                        item.mapping || {},
                        handleExistingLocationMappingChange,
                        item.locationColumns || []
                      )}
                    </div>
                  )}
                </Panel>
              );
            })}
          </Collapse>
        ) : (
          <Typography.Text type="secondary">
            No data sources have been added yet.
          </Typography.Text>
        )}

        <Row style={{ marginTop: 16, justifyContent: "flex-end" }}>
          <Col>
            <Checkbox
              checked={wipeExistingBeforeImport}
              onChange={(e) => setWipeExistingBeforeImport(e.target.checked)}
            >
              Wipe existing rows before import
            </Checkbox>
          </Col>
        </Row>

        {errorMsg && (
          <Typography.Text type="danger" style={{ display: "block", marginTop: 8 }}>
            {errorMsg}
          </Typography.Text>
        )}

        {importedWorksheets.length > 0 && (
          <Row style={{ marginTop: 16 }}>
            <Col flex="auto" />
            <Col>
              <Button
                type="primary"
                icon={<SyncOutlined />}
                onClick={() => handleImportAll()}
                loading={importing}
              >
                Sync
              </Button>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (importConfig) {
      setLocalImportConfig(importConfig);
    }
  }, [importConfig, open]);

  const cleanup = () => {
    setLocalImportConfig({ importedWorksheets: [] });
    setFileList([]);
    setSelectedWorksheet(null);
    setRemoteColumns(null);
    setColumnMapping({});
    setWipeExistingBeforeImport(true);
    setImporting(false);
    setExpandedKeys([]);
    setFileMode("use");
    setFileColumns([]);
    setFileColumnMapping({});
    setCustomLocationCols([]);
    setLocationMappingMode("use");
    setLocationColumnMapping({});
    onClose();
  };

  return (
    <Modal
      title="Import & Sync"
      open={open}
      onCancel={cleanup}
      footer={null}
      width={800}
    >
      <Tabs
        activeKey={activeTab}
        onChange={(key) => {
          setActiveTab(key);
          setErrorMsg(null);
        }}
      >
        <TabPane tab="File Import" key="files">
          {renderFileImportTab()}
        </TabPane>

        <TabPane tab="Location Import" key="location">
          {renderLocationImportTab()}
        </TabPane>

        <TabPane tab="Worksheet Import" key="worksheet">
          {renderWorksheetImportTab()}
        </TabPane>

        <TabPane icon={<SyncOutlined />} tab={"Manage Imports"} key="dataSources">
          {renderDataSourcesTab()}
        </TabPane>
      </Tabs>

      {errorMsg && (
        <Typography.Text type="danger" style={{ display: "block", marginTop: 8 }}>
          {errorMsg}
        </Typography.Text>
      )}
    </Modal>
  );
};

export default ImportFlow;