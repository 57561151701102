import React, { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Button,
  Input,
  Row,
  Col,
  Modal,
  Form,
  Alert,
  Typography,
  Spin,
  Skeleton,
  Dropdown,
  Menu,
  Breadcrumb
} from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  FileExcelOutlined,
  DatabaseOutlined,
  FolderOpenOutlined,
  CaretDownOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { fetchWorksheetData } from '../../endpoints/fetchWorksheetData';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';

const { Text } = Typography;
const colorPrimary = '#4fad96';


const WorksheetsHomePage = ({ userData, setHasError, showHeaders = true}) => {
  const { accessToken } = userData;

  // Holds the list of items (files + folders)
  const [allItems, setAllItems] = useState([]);
  const [currentFolder, setCurrentFolder] = useState('');
  const [loading, setLoading] = useState(false);
  const [showFailedAlert, setShowFailedAlert] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(null);

  // "Create Worksheet" modal
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [newWorksheetName, setNewWorksheetName] = useState('');

  // "Create Folder" modal
  const [createFolderModalVisible, setCreateFolderModalVisible] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');

  // For searching
  const [searchTerm, setSearchTerm] = useState('');

  // ------------------------------------------------
  // Load everything from the server on mount
  // ------------------------------------------------
  useEffect(() => {
    loadAllWorksheets();
  }, []);

  const loadAllWorksheets = async () => {
    setLoading(true);
    setShowFailedAlert(null);
    setShowSuccessAlert(null);

    try {
      const body = { action: 'list_worksheets' };
      const data = await fetchWorksheetData(accessToken, body);

      if (data.error) {
        setShowFailedAlert(data.error);
      } else {
        // data.metadata includes:
        //   { id, name, folder, last_modified, last_modified_by, created_by, isFolder, is_active }
        setAllItems(data.metadata || []);
      }
    } catch (err) {
      setHasError(err);
    } finally {
      setLoading(false);
    }
  };

  // ------------------------------------------------
  // Handler: "New Worksheet"
  // ------------------------------------------------
  const handleNewWorksheetClick = () => {
    setCreateModalVisible(true);
  };

  const handleCreateWorksheet = async () => {

    setShowSuccessAlert('Creating Worksheet...');
    setCreateModalVisible(false);
    setShowSuccessAlert(null);

    try {
      const body = {
        action: 'create',
        name: newWorksheetName.trim() || 'Untitled',
        data: JSON.stringify({ rows: [], cols: [] }),
        folder: currentFolder,
      };
      const result = await fetchWorksheetData(accessToken, body);

      if (result.error) {
        setShowFailedAlert(result.error);
      } else {

        // add to the list 
        const now = new Date().toISOString();
        const newWorksheet = {
          id: result.worksheet_id,
          name: newWorksheetName,
          isFolder: false,
          folder: currentFolder,
          last_modified: now,
          last_modified_by: userData.email,
          created_by: userData.email
        };
        setAllItems([...allItems, newWorksheet]);
        setShowSuccessAlert('Worksheet created');

      }
            
    } catch (e) {
      setShowFailedAlert(e.message);
    } finally {
      setCreateModalVisible(false);
      setNewWorksheetName('');
    }
  };

  useEffect(() => {
      if (showSuccessAlert) {
          const timer = setTimeout(() => {
              setShowSuccessAlert(null);
          }, 5000);
          return () => clearTimeout(timer);
      }
      if (showFailedAlert) {
          const timer = setTimeout(() => {
              setShowFailedAlert(null);
          }, 60000);
          return () => clearTimeout(timer);
      }
  }, [showSuccessAlert, showFailedAlert]);

  // ------------------------------------------------
  // Handler: "New Folder"
  // ------------------------------------------------
  const handleNewFolderClick = () => {
    setCreateFolderModalVisible(true);
  };

  const handleCreateFolder = async () => {
    setShowSuccessAlert('Creating Folder...');
    setCreateFolderModalVisible(false);
    setShowFailedAlert(null);

    try {
      const body = {
        action: 'create_folder',
        name: newFolderName.trim(),
        folder: currentFolder
      };
      const result = await fetchWorksheetData(accessToken, body);

      if (result.error) {
        setShowFailedAlert(result.error);
      } else {
        setShowSuccessAlert('Folder created');
        
        const now = new Date().toISOString();
        // add to the list
        const newFolder = {
          id: currentFolder + '/' + newFolderName + '/',
          name: newFolderName + '/',
          isFolder: true,
          folder: currentFolder,
          last_modified: now,
          last_modified_by: userData.email,
          created_by: userData.email
        };
        setAllItems([...allItems, newFolder]);
      }
    } catch (e) {
      setShowFailedAlert(e.message);
    } finally {
      setNewFolderName('');
    }
  };

  // ------------------------------------------------
  // Deletion logic
  // ------------------------------------------------
  const handleDeleteItem = async (record) => {
    setShowFailedAlert(null);
    setShowSuccessAlert(null);

    if (!record.id) {
      setShowFailedAlert("No worksheet selected to delete");
      return;
    }

    try {
      setShowSuccessAlert('Deleting...');
      const body = {
        action: 'delete',
        isFolder: record.isFolder,
        folder: record.folder || null,
        worksheet_id: record.id
      };
      const result = await fetchWorksheetData(accessToken, body);
      if (result.error) {
        setShowFailedAlert(result.error);
      } else {
        setShowSuccessAlert('Delete successful');

        // remove from the list (even if it's a folder)
        const newItems = allItems.filter((item) => {
              return item.id !== record.id;
          }
        );

        console.log(newItems);
        console.log(record);

        setAllItems(newItems);
      }
    } catch (e) {
      setShowFailedAlert(e.message);
    }
  };

  // ------------------------------------------------
  // Build menu for "Create New"
  // ------------------------------------------------
  const createMenu = (
    <Menu>
      <Menu.Item key="worksheet" icon={<FileExcelOutlined />} onClick={() => handleNewWorksheetClick('worksheet')}>
        Worksheet
      </Menu.Item>
      <Menu.Item key="folder" icon={<FolderOpenOutlined />} onClick={handleNewFolderClick}>
        Folder
      </Menu.Item>
    </Menu>
  );

  // ------------------------------------------------
  // Breadcrumb (folder navigation)
  // ------------------------------------------------
  const pathSegments = currentFolder.split('/').filter(Boolean);

  const handleBreadcrumbClick = (index) => {
    if (index == null) {
      setCurrentFolder('');
      return;
    }
    const newPath = pathSegments.slice(0, index + 1).join('/');
    setCurrentFolder(newPath);
  };

  // ------------------------------------------------
  // Filter + Build table data
  // ------------------------------------------------
  const lowerSearch = searchTerm.toLowerCase();

  // if searching, use all items; if not, filter by current folder
  let itemsToDisplay;
  if (searchTerm.trim()) {
    itemsToDisplay = allItems;
  } else {
    // Original logic (only items in current folder)
    const itemsInFolder = [];
    const subfolders = new Set();

    allItems.forEach((item) => {
      if (!item.folder) item.folder = '';

      if (item.isFolder) {
        const itemPath = item.folder;
        if (itemPath === currentFolder) {
          subfolders.add(item.name.replace('/', ''));
        } else if (itemPath.startsWith(currentFolder + (currentFolder ? '/' : ''))) {
          const relativePath = itemPath.slice(currentFolder.length).replace(/^\//, '');
          const firstSegment = relativePath.split('/')[0];
          subfolders.add(firstSegment);
        }
      } else {
        if (item.folder === currentFolder) {
          itemsInFolder.push(item);
        }
      }
    });

    const folderArray = Array.from(subfolders).map(fname => {
      return allItems.find(item => item.name === fname + '/');
    });

    itemsToDisplay = [...folderArray, ...itemsInFolder];
  }

  // now filter itemsToDisplay by searchTerm (when applicable)
  const filtered = itemsToDisplay.filter(item => {
    const nameMatch = item.name?.toLowerCase().includes(lowerSearch);
    const createdByMatch = item.created_by?.toLowerCase().includes(lowerSearch);
    const modifiedByMatch = item.last_modified_by?.toLowerCase().includes(lowerSearch);
    return nameMatch || createdByMatch || modifiedByMatch;
  });  

  // ------------------------------------------------
  // Table columns (all sortable) + "..." column
  // ------------------------------------------------
  const columns = [
    {
      title: '',
      dataIndex: 'icon',
      key: 'icon',
      width: 50,
      render: (_, record) =>
        record.isFolder
          ? <FolderOpenOutlined style={{fontSize: 18, color: colorPrimary }} />
          : (
              <FileExcelOutlined style={{fontSize: 18, color: 'grey'}} />
          )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '50%',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => {

        if (record.isFolder) {
          const child = record.folder + '/' + record.name;
          // remove first and last slash if present
          const newPath = child.replace(/^\/|\/$/g, '');

          return (
            <span
              style={{ color: '#1890ff', cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => setCurrentFolder(newPath)}
            >
              {text}
            </span>
          );
        } else {
          return (
            <Link
              to={`/orchestrate/worksheet?worksheet_id=${record.id}${
                record.name ? `&folder=${encodeURIComponent(record.folder)}` : ''
              }`}
              style={{ textDecoration: 'underline' }}
            >
              {record.name}
            </Link>
          );
        }
      }
    },
    {
      width: '30%',
      title: 'Last Modified',
      dataIndex: 'last_modified',
      key: 'last_modified',
      sorter: (a, b) => {
        const ad = new Date(a.last_modified || 0).getTime();
        const bd = new Date(b.last_modified || 0).getTime();
        return ad - bd;
      },
      render: (val) => {
        if (!val) return '—';
        return dayjs(val).format('MMM DD, YYYY h:mm A');
      }
    },
    {
      width: '20%',
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      sorter: (a, b) => (a.created_by || '').localeCompare(b.created_by || '')
    },
    // {
    //   width: '15%',
    //   title: 'Modified By',
    //   dataIndex: 'last_modified_by',
    //   key: 'last_modified_by',
    //   sorter: (a, b) => (a.last_modified_by || '').localeCompare(b.last_modified_by || '')
    // },
    showHeaders ? {
      title: '', // The "..." column
      key: 'actions',
      width: 50,
      render: (_, record) => {
        // If it's a folder, we might hide or disable the delete
        const menu = (
          <Menu>
            <Menu.Item
              key="delete"
              icon={<DeleteOutlined />}
              danger
              onClick={() => handleDeleteItem(record)}
            >
              Delete
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button>...</Button>
          </Dropdown>
        );
      }
    } : undefined
  ];

  // ------------------------------------------------
  // Render
  // ------------------------------------------------
  return (
    <div
      style={{
        paddingLeft: '20%',
        paddingRight: '20%',
        paddingTop: 15,
        overflowY: 'auto',
        height: 'calc(100vh - 58px)'
      }}
    >
      {/* Alerts */}
      {showSuccessAlert && (
        <Alert
          message={showSuccessAlert}
          type="success"
          showIcon
          closable
          onClose={() => setShowSuccessAlert(null)}
          style={{
            position: 'absolute',
            bottom: 10,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            width: '25%'
          }}
        />
      )}
      {showFailedAlert && (
        <Alert
          message={showFailedAlert}
          type="error"
          showIcon
          closable
          style={{
            position: 'absolute',
            bottom: 10,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            width: '25%'
          }}
          onClose={() => setShowFailedAlert(null)}
        />
      )}

      {/* Breadcrumb row */}
      { showHeaders &&
        <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleBreadcrumbClick(null)}
                >
                  My Workspace
                </span>
              </Breadcrumb.Item>
              {pathSegments.map((seg, idx) => (
                <Breadcrumb.Item key={idx}>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleBreadcrumbClick(idx)}
                  >
                    {seg}
                  </span>
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </Col>
          <Col>
            <Dropdown overlay={createMenu} trigger={['click']}>
              <Button type="primary" icon={<PlusOutlined />}>
                Create New <CaretDownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
      }

      {/* Search */}
      <Row style={{ marginBottom: 16 }}>
        <Col span={12}>
          <Input
            placeholder="Search all folders..."
            prefix={<SearchOutlined />}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            allowClear
          />
        </Col>
      </Row>

      {/* Table */}
      {loading ? (
        <Skeleton active paragraph={{ rows: 15 }} />
      ) : (
        <Table
          columns={columns}
          dataSource={filtered}
          rowKey={(record) => record.id}
          pagination={false}
        />
      )}

      {/* Create Worksheet modal */}
      <Modal
        title={`Create New Worksheet in ${currentFolder || 'My Workspace'}`}
        open={createModalVisible}
        onCancel={() => setCreateModalVisible(false)}
        onOk={() => handleCreateWorksheet(createModalVisible)}
        okButtonProps={{ disabled: !newWorksheetName.trim() }}
      >
        <Form layout="vertical">
          <Form.Item label={`Worksheet Name`}>
            <Input
              value={newWorksheetName}
              onChange={(e) => setNewWorksheetName(e.target.value)}
              placeholder={createModalVisible === 'worksheet' ? "e.g. Sales Data" : 'e.g. Retail Locations'}
              autoFocus
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* Create Folder modal */}
      <Modal
        title="Create New Folder"
        open={createFolderModalVisible}
        onCancel={() => setCreateFolderModalVisible(false)}
        onOk={handleCreateFolder}
        okButtonProps={{ disabled: !newFolderName.trim() }}
      >
        <Form layout="vertical">
          <Form.Item label="Folder Name">
            <Input
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="e.g. Sales Experiment"
              autoFocus
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default WorksheetsHomePage;